import React from 'react';
import styled from 'styled-components';

import Callback from './components/Callback';
import Feedback from './components/Feedback';

function ContactUs() {
  return (
    <Component>
      <Feedback />
      <Callback />
    </Component>
  );
}

export default ContactUs;

const Component = styled.div`
  max-width: 320px;

  display: flex;
  justify-content: center;
  margin: 0 auto 22px;
`;
