import React from 'react';
import styled from 'styled-components';

import Mts from '@/components/Mts';
import { useSettingItem } from '@/hooks';
import A1 from '@/components/A1';

function Phones() {
  const mts = useSettingItem('CONTACTS_PHONE_MTS');
  const a1 = useSettingItem('CONTACTS_PHONE_MTS');

  return (
    <Component>
      {mts && <Mts title={mts} />}
      {a1 && <A1 title={a1} />}
    </Component>
  );
}

export default Phones;

const Component = styled.div`
  display: flex;
  justify-content: center;
  max-width: 320px;
  margin: 0 auto 16px;

  & > div:last-child {
    margin-left: 10px;
  }
`;
