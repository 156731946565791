import {
  PageFullType,
  ResizedThumbnailType,
  SettingsItemCommon,
  SettingsItemString,
  ThumbnailType,
} from '@tager/web-modules';
import { ButtonFieldValueType, Nullable } from '@tager/web-core';
import { SettingsItemImage } from '@tager/web-modules/src/typings/models';

import {
  CatalogCardVariant,
  CatalogCategoryVariant,
  PaymentIconType,
  StringFieldType,
} from '@/typings/common';

export interface ExamplePageType extends PageFullType {
  path: '/example';
  templateFields: null;
}

export type SettingsItemType =
  | SettingsItemString<'ORDER_FORM_AGREEMENT_TEXT'>
  | SettingsItemString<'USER_NAME'>
  | SettingsItemString<'USER_AGE'>
  | SettingsItemString<'CONTACTS_PHONE_A1'>
  | SettingsItemString<'CONTACTS_PHONE_MTS'>
  | SettingsItemString<'CONTACTS_VIBER'>
  | SettingsItemString<'CONTACTS_WHATSAPP'>
  | SettingsItemString<'CONTACTS_TELEGRAM'>
  | SettingsItemString<'FOOTER_COPYRIGHT'>
  | SettingsItemString<'FOOTER_LOCATION_BUTTON_URL'>
  | SettingsItemString<'WEBSITE_NAME'>
  | SettingsItemImage<'HELPER_IMAGE'>
  | SettingsItemString<'HELPER_TEXT'>
  | SettingsItemString<'HELPER_NAME'>
  | SettingsItemString<'SEO_VOCABULARY_TITLE'>
  | SettingsItemString<'SEO_VOCABULARY_OG_IMAGE'>
  | SettingsItemString<'SEO_VOCABULARY_DESCRIPTION'>
  | SettingsItemString<'HELPER_POSITION'>
  | SettingsItemString<'HELPER_QUOTE'>
  | SettingsItemString<'TEXT_VOCABULARY_DESCRIPTION'>
  | SettingsItemString<'HELPER_NOTE'>
  | SettingsItemString<'ADULTS_MODE_ENABLED'>
  | SettingsItemCreditPrices<'PRICES_CREDIT'>
  | SettingsItemString<'CONTACTS_EMAIL'>
  | SettingsItemString<'TEXT_BLOG_TITLE'>;

export enum MenuItemTypes {
  Top = 'top',
  Main = 'main',
  Footer = 'footer',
  Catalog = 'catalog',
}

export type CatalogMenuType = {
  label: string;
  url: string;
  entityType: 'LINK' | 'CATEGORY' | 'SPECIAL_OFFER';
  entityId: Nullable<number>;
  view: Nullable<'CATEGORIES' | 'PRODUCTS' | 'CATEGORIES_AND_PRODUCTS'>;
  children: {
    header: MenuHeaderType;
    inner: MenuInnerType;
  };
};

export type MenuHeaderType = Array<{
  label: string;
  url: string;
  icon: Nullable<ThumbnailType>;
}>;

export type MenuInnerType = Array<{
  label: string;
  url: StringFieldType;
  children: Array<{
    label: string;
    url: string;
  }>;
}>;
export type MainBannersType = {
  title?: string;
  text?: string;
  image?: Nullable<ThumbnailType>;
  url: string;
  background?: string;
};

export type BannersType = {
  title?: string;
  text?: string;
  image?: ThumbnailType;
  url: string;
};

export type CatalogType = {
  title?: string;
  image?: ResizedThumbnailType<CatalogCardVariant>;
  url: string;
  mode: CatalogCardVariant;
};

export type CatalogMobileType = {
  label: string;
  image?: Nullable<ThumbnailType>;
  url: string;
  isCatalog: boolean;
};

export type ProductsBlockType = {
  title: string;
  productGroup: string;
};

export interface DefaultHomePageType extends PageFullType {
  templateFields: {
    bannersMain: Array<MainBannersType>;
    bannersSidebar: Array<BannersType>;
    catalogTitle: StringFieldType;
    catalogItems: Array<CatalogType>;
    catalogMobileItems: Array<CatalogMobileType>;
    productBlocks: Nullable<Array<ProductsBlockType>>;
    hotProductsTitle: StringFieldType;
    newProductsTitle: StringFieldType;
    blogTitle: StringFieldType;
  };
}

export type ProductPreview = {
  id: number;
  urlAlias: string;
  image: Nullable<ThumbnailType>;
  name: string;
  category: string;
  price: Nullable<number>;
  discountPrice: number;
};

export enum AvailabilityStatus {
  AVAILABLE = 'AVAILABLE',
  ON_REQUEST = 'ON_REQUEST',
  OUT_OF_ORDER = 'OUT_OF_ORDER',
  PRE_ORDER = 'PRE-ORDER',
}

export type AvailabilityTypes =
  | 'AVAILABLE'
  | 'ON_REQUEST'
  | 'OUT_OF_ORDER'
  | 'PRE-ORDER';

export type ProductGalleryType = {
  image: Nullable<{
    preview: ThumbnailType;
    gallery: ThumbnailType;
    original: ThumbnailType;
    tagerAdminView: ThumbnailType;
  }>;
  text: StringFieldType;
};

export type ProductParamsValueType = {
  name: StringFieldType;
  label: string | number;
};

export type ProductSize = {
  value?: number;
  label?: string;
  type?: string;
};

export type FullProductType = {
  id: number;
  code: string;
  type: string;
  availability: AvailabilityTypes;
  waitingDays: number;
  breadcrumbs: Array<{
    name: string;
    link: string;
  }>;
  name: string;
  subtitle: string;
  collection: Nullable<{
    name: string;
    urlAlias: string;
  }>;
  brand: Nullable<{
    name: string;
    urlAlias: string;
  }>;
  price: Nullable<number>;
  discountPrice: Nullable<number>;
  monthPayment: Nullable<number>;
  multiplePricesEnabled: boolean;
  multiplePricesLabel: StringFieldType;
  multiplePricesValues: Nullable<Array<ProductMultiplePricesValuesType>>;
  gallery: Array<ProductGalleryType>;
  params: Array<{
    field: {
      name: string;
      label: string;
      type: 'NUMBER' | 'SELECT' | 'TRUE_FALSE' | 'STRING';
      multiple: boolean;
    };
    value: Nullable<
      | number
      | boolean
      | string
      | ProductParamsValueType
      | Array<ProductParamsValueType>
    >;
  }>;
  related: Nullable<
    Array<{
      name: string;
      options: Array<{
        name: string;
        current: boolean;
        productAlias: StringFieldType;
      }>;
    }>
  >;
  content: string;
  videoYoutubeId: StringFieldType;
  videoUrl: StringFieldType;
  schemaImage: Nullable<{
    preview: ThumbnailType;
    original: ThumbnailType;
    tagerAdminView: ThumbnailType;
  }>;
  pageTitle: string;
  pageDescription: string;
  openGraphImage: StringFieldType;
  size: ProductSize;
  matressSize: ProductSize;
};

export type BaseOfPageType = {
  id: number;
  name: string;
  image: ThumbnailType;
  content: StringFieldType;
  pageTitle: StringFieldType;
  pageDescription: StringFieldType;
  productsCount: Nullable<number>;
  productsMinPrice: Nullable<number>;
  productsMaxPrice: Nullable<number>;
};
export type BaseOfPageNullable = Nullable<BaseOfPageType>;

export type PostImage = {
  cover: Nullable<ThumbnailType>;
  coverBig: Nullable<ThumbnailType>;
  coverSearch: Nullable<ThumbnailType>;
  inner: Nullable<ThumbnailType>;
  openGraph: Nullable<ThumbnailType>;
};
/**
 * Reference:
 * https://github.com/ozitag/tager-backend-blog/blob/1.7.2/src/Resources/Guest/GuestCategoryResource.php
 */
export type CategoryShort = {
  readonly id: number;
  readonly language: StringFieldType;
  readonly name: string;
  readonly urlAlias: string;
};

export type PostShort = {
  readonly id: number;
  readonly urlAlias: string;
  readonly name: string;
  readonly excerpt: StringFieldType;
  readonly image: PostImage;
  readonly category: Nullable<Pick<CategoryShort, 'name' | 'urlAlias'>>;
};

export type CategoryRelation = {
  id: number;
  name: string;
  urlPath: string;
  image: Nullable<ThumbnailType>;
};

export type CatalogExtendedRelation = {
  id: number;
  name: string;
  url: string;
  buttonLabel: string;
  products: Array<ProductType>;
};

export type ProductRelation = {
  name: string;
  items: Array<ProductPreview>;
};
export type OpenGraphImage = {
  readonly url: string;
};
export type SeoInfo = {
  readonly pageTitle: StringFieldType;
  readonly pageDescription: StringFieldType;
  readonly openGraphImage: Nullable<OpenGraphImage>;
};
export type CategoryFull = CategoryShort & SeoInfo;
export type PostSearchShort = {
  readonly id: number;
  readonly language: StringFieldType;
  readonly urlAlias: string;
  readonly title: string;
  readonly date: string;
  readonly excerpt: StringFieldType;
  readonly coverImage: PostImage;
  readonly categories: Array<Pick<CategoryShort, 'name' | 'urlAlias'>>;
};
/**
 * Reference:
 * https://github.com/ozitag/tager-backend-blog/blob/1.7.2/src/Resources/Guest/GuestPostFullResource.php
 */
export type PostFull = PostSearchShort &
  SeoInfo & {
    readonly image: {
      inner: Nullable<ThumbnailType>;
      openGraph: Nullable<ThumbnailType>;
    };
    readonly body: string;
    readonly relatedPosts: Array<PostSearchShort>;
    readonly tags: Array<string>;
  };
export type SeoBlogPageType = Nullable<{
  indexPageTitle: string;
  indexPageDescription: string;
  indexPageOpenGraphImage: Nullable<OpenGraphImage>;
}>;

export type GlossaryCardType = {
  letter: string;
  term: string;
  content: string;
  links: Array<{
    label: string;
    url: string;
  }>;
};

export type ProductFieldsType = {
  name: string;
  label: string;
  value: string;
};

export type NullablePpoductType = Nullable<ProductType>;

export type CatalogCategoryNavigationType = Array<{
  title: string;
  items: Array<{
    label: string;
    urlPath: string;
  }>;
}>;

export type CatalogCategoryType = Nullable<
  BaseOfPageType & {
    type: CatalogCategoryVariant;
    navigation: Nullable<{
      rootUrlAlias: string;
      sections: CatalogCategoryNavigationType;
    }>;

    parents: Array<{
      id: number;
      name: string;
      url: string;
    }>;
  }
>;

export type ProductType = {
  id: number;
  type: string;
  urlAlias: string;
  popularWeight: number;
  image: Nullable<ThumbnailType>;
  availability: Nullable<AvailabilityTypes>;
  name: string;
  brand: Nullable<string>;
  subtitle: StringFieldType;
  price: Nullable<number>;
  discountPrice: Nullable<number>;
  monthPayment: Nullable<number>;
  fields: Array<ProductFieldsType>;
};

export type ProductsCategoryType = {
  id: number;
  name: string;
  icon: StringFieldType;
  urlPath: string;
  productsCount: Nullable<number>;
};

export interface FaqPageType extends PageFullType {
  templateFields: {
    sections: Nullable<
      Array<{
        title?: StringFieldType;
        questions?: Array<{
          question: StringFieldType;
          answer: StringFieldType;
        }>;
      }>
    >;
  };
}

export type DocumentImageType = Nullable<
  ResizedThumbnailType<'cover' | 'original'>
>;

export type PaymentMethodCardType = {
  icon: Nullable<PaymentIconType>;
  image?: ThumbnailType;
  title?: StringFieldType;
  description?: StringFieldType;
  button?: ButtonFieldValueType;
};

export type DocumentCardType = {
  file: DocumentImageType;
  caption?: StringFieldType;
};

export type PaymentCardType = {
  name?: StringFieldType;
  image?: ThumbnailType;
  bankName?: StringFieldType;
  bankUrl?: StringFieldType;
  period?: StringFieldType;
  note?: StringFieldType;
  noteHighlight?: Nullable<boolean>;
  disabledText?: StringFieldType;
};

export interface PaymentPageType extends PageFullType {
  templateFields: {
    items: Nullable<Array<PaymentMethodCardType>>;
    documentsTitle: StringFieldType;
    documentsText: StringFieldType;
    documentsItems: Nullable<Array<DocumentCardType>>;
    cardsTitle: StringFieldType;
    cardsText: StringFieldType;
    cardsItems: Nullable<Array<PaymentCardType>>;
    rassrochkaTitle: StringFieldType;
    rassrochkaNote: StringFieldType;
    rassrochkaContent: StringFieldType;
    eripTitle: StringFieldType;
    eripNote: StringFieldType;
    eripContent: StringFieldType;
  };
}

export type CreditPriceSettingItem = {
  period: StringFieldType;
  initial: StringFieldType;
  underPayment: StringFieldType;
};

export interface SettingsItemCreditPrices<Key extends string>
  extends SettingsItemCommon<Key> {
  value: Array<CreditPriceSettingItem>;
}

export type DeliveryCardType = {
  title?: StringFieldType;
  text?: StringFieldType;
  button?: ButtonFieldValueType;
  prices?: Array<{
    name: StringFieldType;
    price: StringFieldType;
  }>;
};

export interface DeliveryPageType extends PageFullType {
  templateFields: {
    intro: StringFieldType;
    image: ThumbnailType;
    items: Nullable<Array<DeliveryCardType>>;
    mapTitle: StringFieldType;
    textTitle: StringFieldType;
    textContent: StringFieldType;
  };
}

export interface RassrochkaPageType extends PageFullType {
  templateFields: {
    cardsTitle: StringFieldType;
    cardsText: ThumbnailType;
    cardsItems: Nullable<Array<PaymentCardType>>;
  };
}

export type ProductMultiplePricesValuesType = {
  description: StringFieldType;
  name: string;
  availability: AvailabilityTypes;
  price: Nullable<number>;
  discountPrice: Nullable<number>;
  monthPayment: Nullable<number>;
};

export type ImageType = {
  ImageType: Nullable<ThumbnailType>;
};

export type CollectionType = Nullable<
  BaseOfPageType & {
    manufacturer: {
      name: string;
      urlAlias: string;
      image: Nullable<ImageType>;
    };
  }
>;
