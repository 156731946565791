import React from 'react';
import styled from 'styled-components';

import ContentContainer from '@/components/ContentContainer';
import ListedLinks from '@/components/Layout/components/Footer/components/ListedLinks';
import { useTypedSelector } from '@/store/store';
import { selectMenuItemListByAlias } from '@/store/reducers/tager/menus';
import { colors } from '@/constants/theme';

function CustomerServices() {
  const footer = useTypedSelector((state) =>
    selectMenuItemListByAlias(state, 'footer')
  );

  return (
    <Component>
      <Section>
        <ContentContainer>
          {footer && (
            <ListedLinks data={footer[0].children} title={footer[0].label} />
          )}
        </ContentContainer>
      </Section>
    </Component>
  );
}

const Component = styled.div``;

const Section = styled.div`
  padding: 10px 0;
  border-bottom: 1px solid ${colors.grayDivider};
`;

export default CustomerServices;
