import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';

import ContentContainer from '@/components/ContentContainer';
import { breakpoints } from '@/constants/theme';
import Burger from '@/components/Burger';
import Search from '@/components/Search';
import UseModalsOpen from '@/hooks/useModalsOpen';
import LogoProvider from '@/providers/LogoTriggerProvider';

import CatalogBtn from './components/CatalogBtn';
import Logo from './components/Logo';

function Header() {
  const router = useRouter();
  const { isModalVisible } = UseModalsOpen();
  const [catalogActive, setCatalogActive] = useState(false);
  const [menuActive, setMenuActive] = useState(false);

  return (
    <LogoProvider>
      <Component onMobile={isModalVisible} className="main-layout-header">
        <ContentContainer>
          <Content>
            <LogoWrapper
              onClick={() => {
                catalogActive && setCatalogActive(false);
                menuActive && setMenuActive(false);
                router.push('/');
              }}
            >
              <Logo />
            </LogoWrapper>
            <HeaderRight>
              <Search />
              <CatalogBtn menuState={menuActive} trigger={setCatalogActive} />
              <Burger catalogState={catalogActive} trigger={setMenuActive} />
            </HeaderRight>
          </Content>
        </ContentContainer>
      </Component>
    </LogoProvider>
  );
}

export default Header;

const Component = styled.header<{ onMobile: boolean }>`
  position: fixed;
  top: 0;
  display: flex;
  padding: 9.5px 0;
  margin: 0 auto;
  left: 0;
  right: 0;
  max-width: 1260px;

  transition: transform 0s ease;
  ${({ onMobile }) =>
    !onMobile &&
    css`
      transform: translate3d(0, 0, 10px);
    `}
  z-index: 1000;
  background-color: #fff;
  box-shadow: 0 3px 6px 0 #d0d0d0;
  overflow: hidden;

  @media (min-width: ${breakpoints.laptop}px) {
    height: 80px;
  }
  @media (min-width: ${breakpoints.tabletLarge}px) {
    height: 70px;
  }
  @media (min-width: ${breakpoints.tabletSmall}px) {
    height: 60px;
  }
`;
const Content = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  z-index: 2;
`;
const HeaderRight = styled.div`
  display: flex;
  align-items: center;
`;

const LogoWrapper = styled.div``;
