import React, { useContext, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  BodyScrollOptions,
  disableBodyScroll,
  enableBodyScroll,
} from 'body-scroll-lock';

import { ReactComponent as ArrowForward } from '@/assets/svg/Menu/arrowForward.svg';
import { breakpoints, colors } from '@/constants/theme';
import { useCatalogMenuResource } from '@/hooks/useCatalogCategories';
import { ReactComponent as IconForward } from '@/assets/svg/Menu/closeCatalog.svg';
import { ReactComponent as IconBack } from '@/assets/svg/Menu/arrowBack.svg';
import Link from '@/components/Link';
import { CatalogMenuType } from '@/typings/model';
import Portal from '@/components/Portal';
import Picture from '@/components/Picture';
import { LogoContext } from '@/providers/LogoTriggerProvider/LogoTriggerProvider';

interface CatalogInterface {
  isOpen?: boolean;
  data?: CatalogMenuType;
}

interface Props {
  toUnmount?: boolean;
  delay?: boolean;
  trigger: (state: boolean) => void;
}

const CatalogContent = ({ trigger }: Props) => {
  const [catalogIsOpen, setOpenCatalog] = useState(false);
  const [CategorieIsOpen, setCategorieOpen] = useState<CatalogInterface>({
    isOpen: false,
  });

  const modalContent = useRef<HTMLDivElement>(null);
  const { triggerLogo } = useContext(LogoContext);
  const catalogResources = useCatalogMenuResource();
  const catalogList = catalogResources?.data;

  const handleItemClick = (item: CatalogMenuType) => {
    setCategorieOpen({ isOpen: true, data: item });
  };

  const handleCatalogClick = () => {
    setOpenCatalog(!catalogIsOpen);
  };

  const handleCategoriesClick = () => {
    setCategorieOpen((prevState) => ({ ...prevState, isOpen: false }));
  };

  useEffect(() => {
    return () => {
      triggerLogo && triggerLogo(false);
    };
  }, [triggerLogo]);

  useEffect(() => trigger(false), [trigger]);

  useEffect(() => {
    const target = modalContent.current;
    const options: BodyScrollOptions = {
      reserveScrollBarGap: true,
      allowTouchMove: (el) => el.classList.contains('scrollable'),
    };
    if (target) {
      disableBodyScroll(target, options);
    }
    return () => {
      if (target) {
        enableBodyScroll(target);
      }
    };
  }, []);

  useEffect(() => {
    const offset = window.pageYOffset;
    return () => {
      window.scrollTo(0, offset);
    };
  }, []);

  return (
    <Overlay>
      <CatalogContainer ref={modalContent} className="scrollable">
        <List>
          <Heading
            hideBorder={catalogIsOpen}
            active
            open={catalogIsOpen}
            onClick={handleCatalogClick}
          >
            <Label active={!!catalogIsOpen}>
              {catalogIsOpen && <ArrowBack />}
              Весь каталог
            </Label>
            {catalogIsOpen ? <Close /> : <ArrowForward />}
          </Heading>
          {catalogIsOpen &&
            //full catalog
            (catalogList ?? []).map((item) => (
              <Categorie key={item.entityId}>
                <Link to={item.url}>
                  <Label categorieOpened>{item.label}</Label>
                </Link>
                <CategorieInner>
                  {(item.children.header ?? []).map((item, index) => (
                    <Link to={item.url} key={index}>
                      <ContentItem>
                        <Picture
                          src2x={item?.icon?.url ?? ''}
                          srcWebp={item?.icon?.url ?? ''}
                          src={item?.icon?.url ?? ''}
                          srcWebp2x={item?.icon?.url ?? ''}
                        />

                        <Description>{item.label}</Description>
                      </ContentItem>
                    </Link>
                  ))}
                </CategorieInner>
              </Categorie>
            ))}
          {!catalogIsOpen &&
            //categories
            (catalogList ?? []).map((item, index) => (
              <Heading key={index} onClick={() => handleItemClick(item)}>
                <Label>{item.label}</Label>
                <ArrowForward />
              </Heading>
            ))}
          {CategorieIsOpen?.isOpen && (
            <Portal>
              <SubCategories>
                <List>
                  <Heading
                    hideBorder={CategorieIsOpen.isOpen}
                    active
                    open={CategorieIsOpen?.isOpen}
                    onClick={handleCategoriesClick}
                  >
                    <Label active>
                      <ArrowBack />
                      {CategorieIsOpen?.data?.label}
                    </Label>
                    <Close />
                  </Heading>
                  <Categorie
                    hideBorder={
                      !!CategorieIsOpen?.data?.children?.inner?.length
                    }
                  >
                    <CategorieInner>
                      {(CategorieIsOpen?.data?.children.header ?? []).map(
                        (item, index) => (
                          <Link to={item.url} key={item.label + index}>
                            <ContentItem>
                              <Picture
                                src2x={item?.icon?.url ?? ''}
                                srcWebp={item?.icon?.url ?? ''}
                                src={item?.icon?.url ?? ''}
                                srcWebp2x={item?.icon?.url ?? ''}
                              />
                              <Description>{item.label}</Description>
                            </ContentItem>
                          </Link>
                        )
                      )}
                    </CategorieInner>
                  </Categorie>
                  {CategorieIsOpen?.data?.children.inner && (
                    <SubCategorie>
                      {CategorieIsOpen?.data?.children.inner.map(
                        (item, index) => (
                          <Categorie key={item.label + index}>
                            <Label active>{item.label}</Label>
                            {(item?.children ?? []).map((item, index) => (
                              <Link to={item.url} key={item.label + index}>
                                <SubCategorieInner>
                                  {item.label}
                                </SubCategorieInner>
                              </Link>
                            ))}
                          </Categorie>
                        )
                      )}
                    </SubCategorie>
                  )}
                </List>
              </SubCategories>
            </Portal>
          )}
        </List>
      </CatalogContainer>
    </Overlay>
  );
};

export default CatalogContent;

const Overlay = styled.div`
  position: fixed;
  inset: 0;
  margin-top: 45px;
  padding-bottom: 43px;
  z-index: 5;
  min-height: 100%;
  max-height: 100vh;
  background: rgba(0, 0, 0, 0.5);

  @media (min-width: ${breakpoints.laptop}px) {
    margin-top: 80px;
    padding-bottom: 80px;
  }
  @media (min-width: ${breakpoints.tabletLarge}px) {
    margin-top: 70px;
    padding-bottom: 70px;
  }
  @media (min-width: ${breakpoints.tabletSmall}px) {
    margin-top: 60px;
    padding-bottom: 60px;
  }
`;

const CatalogContainer = styled.div`
  max-width: 1260px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  background-color: #fff;
  transform: translateX(0);
  -webkit-overflow-scrolling: touch !important;
  overflow: auto !important;
`;
const Heading = styled.div<{
  active?: boolean;
  open?: boolean;
  hideBorder?: boolean;
}>`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 15px;

  ${({ active }) =>
    active &&
    css`
      span {
        color: ${colors.darkRed};
      }

      svg path {
        fill: ${colors.darkRed};
      }
    `}

  ${({ open }) =>
    open &&
    css`
      background-color: #f0f0f0;
    `}

  border-bottom: 1px solid ${({ hideBorder }) =>
    hideBorder ? 'transparent' : colors.gray70};
`;

const Label = styled.span<{ active?: boolean; categorieOpened?: boolean }>`
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  font-size: ${({ active, categorieOpened }) =>
    !active && !categorieOpened ? '13px' : categorieOpened ? '18px' : '16px'};

  color: #000;
`;

const ArrowBack = styled(IconBack)`
  margin-right: 12px;
`;

const List = styled.ul`
  height: 100%;
  width: 100%;
  overflow: auto;

  margin-top: 1px;
`;

const Close = styled(IconForward)`
  height: 15px;
`;
const SubCategorieInner = styled.div`
  padding: 6px 0;
`;

const SubCategories = styled.div`
  height: 100%;
  width: 100%;

  background: #fff;
`;

const Categorie = styled.div<{ hideBorder?: boolean }>`
  width: 100%;
  padding: 11px 15px 15px;
  border-bottom: 1px solid
    ${({ hideBorder }) => (!hideBorder ? ' #f0f0f0;' : 'transparent;')};
`;

const CategorieInner = styled.div`
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: flex-end;

  gap: 32px 45px;

  @media (min-width: 768px) {
    grid-template-columns: calc(25% - 25px) calc(25% - 25px) calc(25% - 25px);
    justify-content: center;
  } ;
`;

const ContentItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 140px;
  width: 100%;
  margin: 0 auto;

  picture {
    width: 100%;
    height: 100%;
  }

  img {
    height: 100%;
    width: 120px;
    display: block;
    position: relative;
    max-height: 80px;
    overflow: hidden;
    object-fit: contain;
  }

  & > div:first-child {
    margin-bottom: 12.5px;
  }
`;
const Description = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
`;

const SubCategorie = styled.div`
  & > div {
    border: 0;
    border-top: 1px solid #f0f0f0;
  }

  & > div:last-child {
    padding-bottom: 100px;
  }
`;
