import React from 'react';
import styled from 'styled-components';

import { useModal } from '@tager/web-components';

import UseModalsOpen from '@/hooks/useModalsOpen';
import FeedbackFormFooter from '@/components/Forms/FeedbackForm';

function Feedback() {
  const throwModal = useModal();

  const { openModalState } = UseModalsOpen();

  return (
    <Component
      onClick={() => {
        setTimeout(() => {
          openModalState();
          throwModal(
            FeedbackFormFooter,
            {
              title: 'Написать сообщение',
            },
            { withAnimation: false, scrollLockDisabled: true }
          );
        }, 0);
      }}
    >
      <button>Обратная связь</button>
    </Component>
  );
}

export default Feedback;

const Component = styled.div`
  border: 1px solid #860935;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;

  min-height: 34px;
  max-width: 290px;
  min-width: 140px;
  cursor: pointer;

  button {
    width: auto;
    height: auto;
    color: #860935;
    font-weight: bold;
    font-size: 11px;
    line-height: 22px;
  }
`;
