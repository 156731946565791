import React, { useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import Portal from '@/components/Portal';
import { ReactComponent as CatalogIcon } from '@/assets/svg/Header/CatalogBtn.sofa/CatalogBtn.sofa_menu.svg';
import { colors } from '@/constants/theme';
import { breakpoints } from '@/constants/theme';
import CatalogContent from '@/components/CatalogContent';
import { LogoContext } from '@/providers/LogoTriggerProvider/LogoTriggerProvider';

function CatalogBtn({
  menuState,
  trigger,
}: {
  menuState: boolean;
  trigger: (state: boolean) => void;
}) {
  const [clicked, setClicked] = useState(false);
  const [toUnmount, setToUnmount] = useState(false);
  const { isTriggered } = useContext(LogoContext);

  const stateHandler = () => {
    if (!clicked) {
      trigger(true);
      setClicked(true);
    } else {
      trigger(false);
      setToUnmount(true);
      setClicked(false);
      setToUnmount(false);
    }
  };

  useEffect(() => {
    if (!isTriggered) {
      return;
    }
    setToUnmount(true);
    setClicked(false);
    setToUnmount(false);
    trigger(false);
  }, [isTriggered, trigger]);

  useEffect(() => {
    if (clicked && menuState) {
      setToUnmount(true);
      setClicked(!clicked);
      setToUnmount(false);
      trigger(false);
    }
  }, [menuState, trigger]);

  useEffect(() => trigger(false), [trigger]);

  return (
    <>
      <Component toUnmount={toUnmount} active={clicked} onClick={stateHandler}>
        <Icon active={clicked} toUnmount={toUnmount}>
          <CatalogIcon />
        </Icon>
        <Title toUnmount={toUnmount} active={clicked}>
          Каталог
        </Title>
      </Component>
      {clicked && (
        <Portal>
          <CatalogContent trigger={trigger} />
        </Portal>
      )}
    </>
  );
}

export default CatalogBtn;

const Component = styled.div<{ active: boolean; toUnmount: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  max-width: 92px;

  padding: 3px 11px 3px 8px;
  border: 1px solid ${colors.gray70};
  border-radius: 5px;

  @media (min-width: ${breakpoints.tabletSmall}px) {
    max-width: 100px;
  }

  ${({ active, toUnmount }) =>
    active &&
    !toUnmount &&
    css`
      border-color: ${colors.darkRed};
      background-color: ${colors.darkRed};
    `}
`;

const Icon = styled.div<{ active: boolean; toUnmount: boolean }>`
  display: flex;
  margin-right: 7px;
  line-height: 0;

  ${({ active, toUnmount }) =>
    active &&
    !toUnmount &&
    css`
      svg path {
        fill: #fff;
      }
    `}
`;

const Title = styled.span<{ active: boolean; toUnmount: boolean }>`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: ${colors.black100};
  @media (min-width: ${breakpoints.tabletSmall}px) {
    font-size: 14px;
  }
  ${({ active, toUnmount }) =>
    active &&
    !toUnmount &&
    css`
      color: #fff;
    `}
`;
