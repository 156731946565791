export const colors = {
  white: '#fff',
  black: 'black',
  black100: '#101010',
  black200: '#262626',
  black300: '#3b3b3b',
  gray: '#f5f5f5',
  gray50: '#EFEFEF',
  gray100: '#e9e9e9',
  gray182: '#b6b6b6',
  gray200: '#d2d2d2',
  gray300: '#a8a8a8',
  gray400: '#5a5a5a',
  gray500: '#666666',
  gray237: '#EDEDED',
  gray700: '#6A6A6A',
  gray800: '#E6E6E6',
  gray900: '#626262',
  grayDark: '#5f5f5f',
  grayLight: '#999999',
  grayDivider: '#dadada',
  gray70: '#c6c6c6',
  grayLighter: '#E5E5E5',
  grayBackground: '#ECECEC',
  grayCart: '#f0f0f0',
  grayProductCard: '#e9e9e9',
  grayFooterBack: '#f6f6f6',
  darkGrey: '#686868',
  green: '#36ba5f',
  red: 'red',
  darkRed: '#860935',
  primary: 'var(--main-color, #860935)',
  primary100: 'var(--main-color-hover, #6a072a)',
} as const;

/** Source: https://htmlacademy.ru/blog/useful/css/short-14 */
const fallbackFont = [
  '-apple-system',
  "'BlickMacSystemFont'",
  "'Segoe UI'",
  "'Roboto'",
  "'Oxygen'",
  "'Ubuntu'",
  "'Cantarell'",
  "'Fira Sans'",
  "'Droid Sans'",
  "'Helvetica Neue'",
  'sans-serif',
].join(',');

export const fonts = {
  Montserrat: `'Montserrat', ${fallbackFont}`,
};

/**
 * Reference - Screen Resolution Stats Worldwide:
 * https://gs.statcounter.com/screen-resolution-stats
 *
 * 16px - scrollbar width on Windows,
 * some browsers doesn't include scrollbar width when calculate media queries
 */
export const breakpoints = {
  /** iPhone 5/SE */
  mobileSmall: 320,
  /** iPhone 6/7/8/X */
  mobileMedium: 375,
  /** iPhone 6/7/8 Plus */
  mobileLarge: 414,
  /** iPad 1, 2, Mini and Air */
  tabletSmall: 768,
  tabletLarge: 1024,
  /** 1280 - 16 = 1264 -> 1260 - more beautiful number :) */
  laptop: 1260,
  /** 1536 - 16 = 1520 -> 1500 - more beautiful number :) */
  desktop: 1500,
};
