import React from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

import { breakpoints } from '@/constants/theme';

interface PortalProps {
  children: JSX.Element;
}

function Portal({ children }: PortalProps) {
  return createPortal(<Component>{children}</Component>, document.body);
}

const Component = styled.div`
  position: fixed;
  width: 100vw;
  height: calc(100vh - 45px);
  top: 45px;
  z-index: 10;

  @media (min-width: ${breakpoints.laptop}px) {
    height: calc(100vh - 80px);
    top: 80px;
  }
  @media (min-width: ${breakpoints.tabletLarge}px) {
    height: calc(100vh - 70px);
    top: 70px;
  }
  @media (min-width: ${breakpoints.tabletSmall}px) {
    height: calc(100vh - 60px);
    top: 60px;
  }
`;

export default Portal;
