import React, { ReactElement, useState } from 'react';

type State = {
  isTriggered: boolean;
  triggerLogo?: (state: boolean) => void;
};

const defaultState: State = {
  isTriggered: false,
};

export const LogoContext = React.createContext(defaultState);

interface LogoProviderInterface {
  children?: ReactElement;
}

function LogoProvider({ children }: LogoProviderInterface) {
  const [triggered, setTriggered] = useState(false);

  const triggerLogo = (state: boolean) => {
    setTriggered((prev) => state);
    setTimeout(() => setTriggered(false), 0);
  };

  const values = {
    isTriggered: triggered,
    triggerLogo,
  };

  return <LogoContext.Provider value={values}>{children}</LogoContext.Provider>;
}

export default LogoProvider;
