import styled, { css } from 'styled-components';

import { CssSnippet } from '@tager/web-components';

import { colors } from '@/constants/theme';

import { ButtonVariant } from './Button.types';

const primaryOutlineMixin = css`
  border: 1px solid ${colors.primary};
  color: ${colors.primary};

  &:hover {
    color: ${colors.white};
    background: ${colors.primary};
  }
`;

const primaryContainedMixin = css`
  background: ${colors.primary};
  color: ${colors.white};
  border: 1px solid ${colors.primary};

  &:hover {
    background: ${colors.primary100};
  }
`;

const largeSizeMixin = css`
  line-height: 40px;
  padding: 0 20px;
`;

const customSizeMixin = css`
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  padding: 7px 20px;
`;
const smallSizeMixin = css`
  height: 30px;
  line-height: 28px;
  padding: 0 12px;
`;

const variantCssMap: Record<ButtonVariant, CssSnippet> = {
  outlined: css`
    background-color: #24292e;
    opacity: 0.95;
    color: white;
    border: 1px solid #24292e;

    &:hover {
      background-color: black;
    }
  `,
  contained: css`
    background-color: white;
    color: #24292e;
    border: 1px solid #24292e;

    &:hover {
      background-color: #24292e;
      opacity: 0.95;
      color: white;
    }
  `,
  'call-me-solid': css`
    background: transparent;
    border: 1px solid #b7b7b7;
    font-size: 13px;
    color: inherit;
    ${smallSizeMixin};

    &:hover {
      color: ${colors.black};
      background: #eeeeee;
    }

    &:hover[disabled] {
      background: transparent;
    }
  `,
  'call-me-dashed': css`
    border: 1px dashed #dfdfdf;
    background: transparent;
    font-size: 13px;
    ${smallSizeMixin};

    &:hover {
      border-color: ${colors.black};
    }

    &:hover[disabled] {
      border-color: #dfdfdf;
    }
  `,
  'outlined-primary': css`
    font-size: 16px;
    font-weight: 600;

    ${primaryOutlineMixin}
    ${largeSizeMixin}
    &:hover {
      background: ${colors.primary};
      color: ${colors.white};
    }

    &:hover[disabled] {
      background: ${colors.white};
      color: ${colors.primary};
    }
  `,
  'contained-primary': css`
    font-size: 16px;
    font-weight: 500;

    ${largeSizeMixin};
    ${primaryContainedMixin};

    &:hover[disabled] {
      background-color: ${colors.primary};
    }
  `,

  'contained-primary-w100': css`
    font-size: 20px;
    font-weight: 500;
    width: 100%;

    ${primaryContainedMixin};

    ${largeSizeMixin}
    &:hover[disabled] {
      background-color: ${colors.primary};
    }
  `,
  'contained-primary-w100-f13': css`
    font-size: 13px;
    font-weight: 500;
    width: 100%;

    ${primaryContainedMixin};

    ${largeSizeMixin}
    &:hover[disabled] {
      background-color: ${colors.primary};
    }
  `,
  'outlined-primary-w100': css`
    font-weight: 500;
    font-size: 15px;
    width: 100%;

    ${largeSizeMixin}
    ${primaryOutlineMixin}
    &:hover[disabled] {
      background: ${colors.white};
      color: ${colors.primary};
    }
  `,
  'outlined-primary-w100-f14': css`
    font-weight: 500;
    font-size: 14px;
    width: 100%;

    ${largeSizeMixin}
    ${primaryOutlineMixin}
    &:hover[disabled] {
      background: ${colors.white};
      color: ${colors.primary};
    }
  `,
  'outlined-primary-w100-f13': css`
    ${customSizeMixin}
    ${primaryOutlineMixin}
    &:hover[disabled] {
      background: ${colors.white};
      color: ${colors.primary};
    }
  `,
};

export const StyledButton = styled.button<{
  variant?: ButtonVariant;
  loading?: boolean;
  width?: string;
}>`
  ${(props) =>
    typeof props.variant === 'string'
      ? variantCssMap[props.variant]
      : props.variant};

  display: block;
  position: relative;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  transition: 0.3s;
  cursor: pointer;
  ${(props) =>
    props.loading
      ? css`
          pointer-events: none;
          opacity: 70%;
        `
      : css``};

  &:disabled {
    cursor: default;
    opacity: 70%;
  }
  ${(props) =>
    props.width
      ? css`
          width: ${props.width};
        `
      : null}
`;
