import React, { useContext } from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';

import { useModal } from '@tager/web-components';

import { ReactComponent as SearchIcon } from '@/assets/svg/Menu/search.svg';
import { queryAdded, selectQuery } from '@/store/reducers/pages/search';
import SearchInput from '@/components/Input/SearchInput/SearchInput';
import { breakpoints } from '@/constants/theme';
import UseModalsOpen from '@/hooks/useModalsOpen';
import { LogoContext } from '@/providers/LogoTriggerProvider/LogoTriggerProvider';

import SearchModal from './components/SearchModal';

function Search() {
  const dispatch = useDispatch();
  const searchQuery = useSelector(selectQuery);
  const { openModalState } = UseModalsOpen();
  const router = useRouter();
  const openModal = useModal();
  const { triggerLogo } = useContext(LogoContext);

  const OpenSearchModal = () => {
    openModalState();
    triggerLogo?.(true);
    openModal(
      SearchModal,
      {},
      { withAnimation: false, scrollLockDisabled: true }
    );
  };

  const openModalOrPage = !!searchQuery.trim().length
    ? () => router.push(`/search?query=${searchQuery}`)
    : OpenSearchModal;

  return (
    <Component>
      <StyledSearchInput
        variant="default"
        placeholder="Поиск"
        value={searchQuery}
        onChange={(e) => {
          dispatch(queryAdded(e.target.value));
          OpenSearchModal();
        }}
        onKeyDown={(event) =>
          event.keyCode === 13 && !!searchQuery.trim().length
            ? (router.push(`/search?query=${searchQuery}`),
              dispatch(queryAdded('')))
            : OpenSearchModal
        }
        onClickButton={openModalOrPage}
      />
      <StyledSearchIcon onClick={OpenSearchModal} />
    </Component>
  );
}

const StyledSearchInput = styled(SearchInput)`
  width: 100%;
  display: none;

  @media (min-width: ${breakpoints.tabletSmall}px) {
    display: block;
  }
`;

const Component = styled.div`
  cursor: pointer;
  margin-right: 10px;
  display: flex;
  align-items: center;
`;

const StyledSearchIcon = styled(SearchIcon)`
  display: block;
  @media (min-width: ${breakpoints.tabletSmall}px) {
    display: none;
  }
`;

export default Search;
