import React from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import styled from 'styled-components';

import { FeedbackFormPayload, sendFeedbackForm } from '@/services/requests';
import Button from '@/components/Button';
import { TextInputFormik } from '@/components/TextInput';
import { TextAreaFormik } from '@/components/TextArea';
import {
  areAllErrorsVisible,
  validatePhone,
} from '@/components/TextInput/formikHelpers/form.helpers';
import UseModalsOpen from '@/hooks/useModalsOpen';

function validate({ phone, name }: FeedbackFormPayload) {
  const errors: Partial<FeedbackFormPayload> = {};
  if (!validatePhone(phone)) {
    errors.phone = 'Неверный номер телефона';
  }
  if (!phone) {
    errors.phone = 'Поле обязательно для заполнения';
  }
  if (!name) {
    errors.name = 'Поле обязательно для заполнения';
  }
  return errors;
}

const hasFrontendValidationErrors = (values: FeedbackFormPayload) =>
  !!Object.keys(validate(values)).length;

function FeedbackRequest({ setFormClosed }: { setFormClosed: () => void }) {
  const { closeModalState } = UseModalsOpen();

  function handleSubmit(
    values: FeedbackFormPayload,
    formikHelpers: FormikHelpers<FeedbackFormPayload>
  ) {
    return sendFeedbackForm(values)
      .then(() => {
        setFormClosed();
        closeModalState();
        formikHelpers.setSubmitting(true);
      })
      .catch(() => {
        formikHelpers.setErrors({
          message: 'Произошла ошибка при отправке данных',
        });
        formikHelpers.setStatus({
          hasBackendError: true,
        });
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
      });
  }
  return (
    <Body>
      <Text>Оставьте ваши контакты и мы перезвоним в течение 15 минут</Text>
      <Formik<FeedbackFormPayload>
        initialValues={{ name: '', phone: '', message: '' }}
        onSubmit={handleSubmit}
        validate={validate}
      >
        {({ isSubmitting, isValid, values, dirty, ...props }) => (
          <Form>
            <FormRow>
              <TextInputFormik
                name="name"
                icon="profile"
                placeholder="Как вас зовут?"
                invalid={false}
                autoComplete="off"
              />
            </FormRow>
            <FormRow>
              <TextInputFormik
                name="phone"
                icon="phone"
                placeholder="Ваш номер телефона"
                invalid={false}
                autoComplete="off"
              />
            </FormRow>
            <FormRow>
              <StyledTextArea
                placeholder="Введите ваше сообщение"
                name="message"
                invalid={false}
              />
            </FormRow>
            <Button
              type="submit"
              variant="contained-primary-w100-f13"
              disabled={
                (!isValid || hasFrontendValidationErrors(values)) &&
                areAllErrorsVisible(props.errors, props.touched)
              }
              children="Отправить"
              loading={isSubmitting}
            />
          </Form>
        )}
      </Formik>
    </Body>
  );
}

export default FeedbackRequest;

const Text = styled.div`
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 20px;
`;

const FormRow = styled.div`
  margin-bottom: 30px;
`;

const Body = styled.div`
  margin-top: 20px;
  padding: 0 15px;
`;

const StyledTextArea = styled(TextAreaFormik)`
  textarea {
    height: 160px;
  }
`;
