import React from 'react';
import styled from 'styled-components';

import { breakpoints, colors } from '@/constants/theme';

import Header from './components/Header';
import Footer from './components/Footer';

interface Props extends LayoutCtxInterface {
  children?: React.ReactNode;
  isPreloaderHidden?: boolean;
}

interface LayoutCtxInterface {
  disabled?: boolean;
  toggleDisabled?: () => void;
}

function Layout({ children }: Props) {
  return (
    <Container>
      <Header />
      <Main>{children}</Main>
      <Footer />
    </Container>
  );
}

export default Layout;

const Container = styled.div`
  background-color: ${colors.white};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1260px;
  margin: 0 auto;
`;

const Main = styled.main`
  margin-top: 45px;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakpoints.laptop}px) {
    margin-top: 80px;
  }
  @media (min-width: ${breakpoints.tabletLarge}px) {
    margin-top: 70px;
  }
  @media (min-width: ${breakpoints.tabletSmall}px) {
    margin-top: 60px;
  }
`;
