import React from 'react';
import styled from 'styled-components';

import ContentContainer from '@/components/ContentContainer';
import { colors } from '@/constants/theme';
import MapPointer from '@/components/MapPointer';
import Phones from '@/components/Phones';
import ContactUs from '@/components/Layout/components/Footer/components/Contacts/components/ContactUs';

function Contacts() {
  return (
    <ContactsSection>
      <Wrapper>
        <Phones />
        <ContactUs />
        <MapPointer />
      </Wrapper>
    </ContactsSection>
  );
}

const ContactsSection = styled.div`
  border-bottom: 1px solid ${colors.grayDivider};

  & > div {
    display: flex;
    flex-direction: column;
    max-width: 460px;
    margin: 0 auto;
  }
`;

const Wrapper = styled.div`
  padding: 0 15px;

  & > a > div:first-child {
    margin: 0 auto 20px;
  }
`;

export default Contacts;
