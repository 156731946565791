import React, { useMemo } from 'react';
import styled from 'styled-components';

import { Nullable } from '@tager/web-core';

import { ProductFieldsType } from '@/typings/model';

import ParamBasis from '../ParamsBasis';
import { ParamFull } from '../ParamsBasis.style';

type FullProps = {
  fields: Array<ProductFieldsType>;
  className?: string;
  brand: Nullable<string>;
};

type ValueType = Array<{
  label: Nullable<string>;
  value: string;
}>;

function ProductCardParamsBasicFull({ fields, brand, className }: FullProps) {
  const fullFields = useMemo(() => {
    const mechanism: ValueType = [];

    const sizeSleepValue: ValueType = [];

    const sizeValues: ValueType = [];

    const result: Array<{ name: string; values: ValueType }> = [];

    const brands: ValueType = [{ label: null, value: brand ?? '' }];

    fields.forEach((field) => {
      if (
        field.name === 'DLINA' ||
        field.name === 'GLUBINA' ||
        field.name === 'VYSOTA'
      ) {
        sizeValues.push({
          label: field.label,
          value: field.value,
        });
      }
      if (
        field.name === 'DLINA-SPALNOGO-MESTA' ||
        field.name === 'SHIRINA-SPALNOGO-MESTA'
      ) {
        sizeSleepValue.push({
          label: field.label,
          value: field.value,
        });
      }
      if (field.name === 'MEHANIZM-TRANSFORMACII') {
        mechanism.push({
          label: null,
          value: field.value,
        });
      }
    });

    if (!!sizeValues.length)
      result.push({
        name: 'Размеры',
        values: sizeValues,
      });

    if (!!sizeSleepValue.length)
      result.push({
        name: 'Размеры спального места',
        values: sizeSleepValue,
      });

    if (!!mechanism.length)
      result.push({
        name: 'Механизм',
        values: mechanism,
      });

    if (brand)
      result.push({
        name: 'Производитель',
        values: brands,
      });

    return result;
  }, [brand, fields]);

  const brandsAndMechanismList: Array<{ name: string; values: ValueType }> = [];

  return (
    <Full className={className}>
      {fullFields.map((field) => {
        if (field.name === 'Механизм' || field.name === 'Производитель') {
          brandsAndMechanismList.unshift(field);
          return null;
        }
        return (
          <Cell key={field.name}>
            <Title>{field.name}</Title>
            {Array.isArray(field.values) ? (
              field.values.map((value, index) => (
                <ParamFull key={index}>
                  <ParamBasis param={value} />
                </ParamFull>
              ))
            ) : (
              <ParamFull key={field.values}>
                <ParamBasis param={field.values} />
              </ParamFull>
            )}
          </Cell>
        );
      })}
      {!!brandsAndMechanismList.length ? (
        <InnerContainer>
          {brandsAndMechanismList.map((field) => (
            <Cell key={field.name}>
              <Title>{field.name}</Title>
              {Array.isArray(field.values) ? (
                field.values.map((value, index) => (
                  <ParamFull key={index}>
                    <ParamBasis param={value} />
                  </ParamFull>
                ))
              ) : (
                <ParamFull key={field.values}>
                  <ParamBasis param={field.values} />
                </ParamFull>
              )}
            </Cell>
          ))}
        </InnerContainer>
      ) : null}
    </Full>
  );
}

const Full = styled.div`
  display: flex;
  max-width: 320px;
`;

const Cell = styled.div`
  flex: 1;
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }
`;

const InnerContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 15px;

  & > div {
    margin-bottom: 15px;
  }
`;

const Title = styled.span`
  display: block;
  font-weight: 500;
  font-size: 13px;
  line-height: 100%;
  margin-bottom: 10px;
`;

export default ProductCardParamsBasicFull;
