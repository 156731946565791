import React from 'react';
import styled from 'styled-components';

import { colors } from '@/constants/theme';
import Contacts from '@/components/Layout/components/Footer/components/Contacts';
import Customers from '@/components/Layout/components/Footer/components';
import Catalog from '@/components/Layout/components/Footer/components/Catalog';
import Desktop from '@/components/Layout/components/Footer/components/Desktop';
import About from '@/components/Layout/components/Footer/components/About';

import TagerInfo from './components/Tager';

function Footer() {
  return (
    <Component>
      <Content>
        <Contacts />
        <Customers />
        <Catalog />
        <Desktop />
        <About />
        <TagerInfo />
      </Content>
    </Component>
  );
}

const Component = styled.footer`
  padding: 15px 0 8px;
  margin-top: 30px;

  background-color: ${colors.grayFooterBack};
`;

const Content = styled.div``;

export default Footer;
