import React, { useContext } from 'react';

import {
  ModalsOpenStateContext,
  ModalsOpenStateType,
} from '@/providers/ModalsOpenProvider/ModalsOpenProvider/ModalsOpenState';

function UseModalsOpen() {
  const context = useContext(ModalsOpenStateContext);

  if (!context) {
    throw new Error('use only provider hook');
  }
  return context as ModalsOpenStateType;
}

export default UseModalsOpen;
