import React from 'react';
import styled from 'styled-components';

import { ReactComponent as MapPointerLogo } from '@/assets/svg/Footer/Map.point.icon/Map.point.logo.svg';
import { colors } from '@/constants/theme';
import { useSettingItem } from '@/hooks';
import Link from '@/components/Link';

function MapPointer() {
  const link = useSettingItem('FOOTER_LOCATION_BUTTON_URL');
  return (
    <Component>
      <StyledLink to={link} />
      <Icon>
        <MapPointerLogo />
      </Icon>
      <Description>Показать магазин в ТЦ Глобо на карте</Description>
    </Component>
  );
}

const StyledLink = styled(Link)`
  position: absolute;
  inset: 0;
  z-index: 10;
  margin: 0 auto;
  width: 100%;
  height: 100%;
`;

const Description = styled.div`
  white-space: nowrap;
  margin-left: 5px;
`;

const Component = styled.div`
  cursor: pointer;
  position: relative;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  align-self: center;

  width: 100%;
  min-height: 34px;
  max-width: 290px;
  padding: 5px;
  font-weight: 600;
  font-size: 11px;
  line-height: 22px;

  border: 1px solid ${colors.darkRed};
  border-radius: 6px;
  color: ${colors.darkRed};
`;

const Icon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default MapPointer;
