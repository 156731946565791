import React from 'react';
import styled from 'styled-components';

import { FETCH_STATUSES } from '@tager/web-core';

import SearchTitle from '@/components/Title/SearchTitle';
import { selectFindPostListResource } from '@/store/reducers/pages/search';
import { useTypedSelector } from '@/store/store';
import PlaceholderCard from '@/components/PlaceholderCard';
import PostCard from '@/components/PostCard';

function SearchModalResultBlog() {
  const posts = useTypedSelector((state) =>
    selectFindPostListResource(state, 'searchInput')
  );

  if (!posts.data.length && posts.status === FETCH_STATUSES.SUCCESS) {
    return <SearchTitle title="По вашему запросу ничего не найдено" />;
  }

  return (
    <Component>
      {posts.status === FETCH_STATUSES.LOADING
        ? [1].map((num) => (
            <PostWrapper key={num}>
              <PlaceholderCard height={170} />
            </PostWrapper>
          ))
        : posts.data.map((post) => (
            <PostWrapper key={post.id}>
              <PostCard
                variant="search"
                post={{
                  name: post.title,
                  image: post.coverImage,
                  category: post.categories[0],
                  ...post,
                }}
              />
            </PostWrapper>
          ))}
    </Component>
  );
}

const Component = styled.ul`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;

const PostWrapper = styled.li`
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export default SearchModalResultBlog;
