import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import MenuLayout from '@/components/MenuLayout';
import Portal from '@/components/Portal';
import Menu from '@/components/MenuLayout/components/Menu';
import { LogoContext } from '@/providers/LogoTriggerProvider/LogoTriggerProvider';

function Burger({
  catalogState,
  trigger,
}: {
  catalogState: boolean;
  trigger: (state: boolean) => void;
}) {
  const [isOpen, setOpen] = useState(false);
  const [animate, setAnimate] = useState(false);

  const { isTriggered: logoClicked } = useContext(LogoContext);

  const menuOnClose = useCallback(() => {
    trigger(false);
    setAnimate(false);
    setTimeout(() => {
      setOpen(false);
    }, 0);
  }, [trigger]);

  const menuOnOpen = useCallback(() => {
    trigger(true);
    setAnimate(true);
    setOpen(true);
  }, [trigger]);

  const burgerStateHandler = () => {
    if (!isOpen) {
      menuOnOpen();
    } else {
      menuOnClose();
    }
  };

  useEffect(() => {
    if (logoClicked || catalogState) {
      menuOnClose();
    }
  }, [catalogState, logoClicked, menuOnClose]);

  useEffect(() => () => trigger(false));

  return (
    <>
      {isOpen && (
        <Portal>
          <MenuLayout>
            <Menu menuStateHandler={menuOnClose} />
          </MenuLayout>
        </Portal>
      )}
      <BurgerElement open={animate} onClick={burgerStateHandler}>
        <div />
      </BurgerElement>
    </>
  );
}

export default Burger;

const BurgerElement = styled.div<{ open: boolean }>`
  border: none;
  cursor: pointer;
  width: 24px;
  height: 14px;
  margin-left: 15px;
  position: relative;
  z-index: 10;

  &:focus {
    outline: none;
  }

  div {
    transition: opacity 0.2s ease;
    opacity: 1;
    position: absolute;
    top: 6px;
    height: 2px;
    width: 100%;
    background-color: #000;
    ${({ open }) =>
      open &&
      css`
        opacity: 0;
      `}
  }

  &::before,
  &::after {
    transition: transform 0.1s ease;
    display: block;
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: #000;
  }

  &::before {
    top: 0;
    ${({ open }) =>
      open &&
      css`
        transform: rotate(45deg);
        top: 6px;
      `}
  }

  &::after {
    bottom: 0;
    ${({ open }) =>
      open &&
      css`
        transform: rotate(-45deg);
        bottom: 6px;
      `}
  }
`;
