import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { MenuItemType } from '@tager/web-modules/dist/typings/models';

import { ReactComponent as ArrowIcon } from '@/assets/svg/Footer/Arrow.icon/arrow.svg';
import Link from '@/components/Link';
import { colors } from '@/constants/theme';

type ButtonEvent = React.MouseEvent<HTMLDivElement>;

interface ListedLinksProps {
  data: MenuItemType[];
  title: string;
}

function ListedLinks({ data, title }: ListedLinksProps) {
  const [arrowActive, setArrowActive] = useState<boolean>(true);
  const [collapseHeight, setCollapseHeight] = useState(0);

  const collapseRef = useRef<HTMLDivElement>(null);

  const showServices = (event: ButtonEvent) => {
    setArrowActive(!arrowActive);
  };

  useEffect(() => {
    const size = collapseRef?.current?.clientHeight;
    size && setCollapseHeight(size);
  }, []);

  return (
    <>
      <Trigger onClick={showServices}>
        <div>{title}</div>
        <ArrowWrapper onClick={showServices} arrowActive={arrowActive}>
          <ArrowIcon />
        </ArrowWrapper>
      </Trigger>
      <Collapse
        arrowActive={arrowActive}
        ref={collapseRef}
        size={collapseHeight}
      >
        <CollapseContent>
          <List>
            {data?.map(({ id, label, link }) => (
              <li key={id}>
                <Link to={link}>{label}</Link>
              </li>
            ))}
          </List>
        </CollapseContent>
      </Collapse>
    </>
  );
}

const Trigger = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > div {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;

    color: ${colors.black};
  }
`;

const ArrowWrapper = styled.div`
  display: inherit;
  cursor: pointer;
  transform: ${({ arrowActive }: { arrowActive: boolean }) =>
    arrowActive ? 'rotate(0deg)' : 'rotate(180deg)'};
  transition: transform 0.2s linear;
  padding: 0 2px;
`;

const List = styled.ul`
  & > li {
    font-size: 13px;
    line-height: 18px;
  }

  li:not(:first-child) {
    margin-top: 4px;
  }
`;

interface CollapseProps {
  size: number;
  arrowActive: boolean;
}

const Collapse = styled.div`
  transition: max-height 0.2s linear;
  overflow: hidden;

  ${({ size }: CollapseProps) =>
    size ? 'max-height:' + (size + 'px;') : null};
  ${({ arrowActive }: CollapseProps) =>
    !arrowActive ? 'max-height: 0' : null};
`;

const CollapseContent = styled.div`
  margin-top: 6px;
`;

export default ListedLinks;
