import React, { ReactNode, useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import {
  BodyScrollOptions,
  disableBodyScroll,
  enableBodyScroll,
} from 'body-scroll-lock';

import { LogoContext } from '@/providers/LogoTriggerProvider/LogoTriggerProvider';
import { breakpoints } from '@/constants/theme';

interface Props {
  onClose?: boolean;
  children: ReactNode;
  isClosing?: boolean;
  delay?: boolean;
}

function MenuLayout({ children }: Props) {
  const { triggerLogo } = useContext(LogoContext);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    return () => triggerLogo && triggerLogo(false);
  }, [triggerLogo]);

  useEffect(() => {
    const target = ref.current;
    const options: BodyScrollOptions = {
      reserveScrollBarGap: true,
      allowTouchMove: (el) => el.classList.contains('scrollable'),
    };
    if (target) {
      disableBodyScroll(target, options);
    }
    return () => {
      if (target) {
        enableBodyScroll(target);
      }
    };
  }, []);

  useEffect(() => {
    const offset = window.pageYOffset;
    return () => {
      window.scrollTo(0, offset);
    };
  }, []);

  return (
    <Component>
      <ModalOverlay />
      <ModalContainer>
        <Scrolling ref={ref} className="scrollable">
          {children}
        </Scrolling>
      </ModalContainer>
    </Component>
  );
}

export default MenuLayout;

const Component = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;

  margin: 45px auto 0;

  @media (min-width: ${breakpoints.laptop}px) {
    margin-top: 80px;
    padding-bottom: 80px;
  }
  @media (min-width: ${breakpoints.tabletLarge}px) {
    margin-top: 70px;
    padding-bottom: 70px;
  }
  @media (min-width: ${breakpoints.tabletSmall}px) {
    margin-top: 60px;
    padding-bottom: 60px;
  }
`;

const Scrolling = styled.div`
  height: 100%;
  -webkit-overflow-scrolling: touch !important;
  overflow: auto !important;
`;

const ModalOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: 1260px;
  margin: 0 auto;
  transform: translateX(0);
  background-color: #fff;
  -webkit-overflow-scrolling: touch !important;
  overflow: auto !important;
`;
