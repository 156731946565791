import React from 'react';

import { LinkToPropType } from '@tager/web-components';
import { createLinkComponent } from '@tager/web-components';

export const linkConverter = (link: LinkToPropType) => link;

const TagerLink = createLinkComponent({ converter: linkConverter });

export const isLinkForOldWeb = (link: string | any): boolean => {
  let validRegexes = [];

  if (process.env.NEXT_PUBLIC_ENV === 'production') {
    validRegexes = [
      /^\/$/,
      /^\/(oplata|dostavka|faq|kontakty|rassrochka)$/,
      /^\/(brands|collections|products|catalog)\/.+/,
    ];
  } else {
    return false;
  }

  for (let i = 0; i < validRegexes.length; i++) {
    if (validRegexes[i].test(link)) {
      return false;
    }
  }

  return true;
};

const isLinkReplacingEnabled = (): boolean =>
  process.env.NEXT_PUBLIC_ENV === 'production';

const Link = (props: any) =>
  isLinkReplacingEnabled() && isLinkForOldWeb(props.to) ? (
    <a {...props} href={props.to}>
      {props.children}
    </a>
  ) : (
    <TagerLink {...props} />
  );

export default Link;
