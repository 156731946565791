import React from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';

import Mts from '@/components/Mts';
import MapPointer from '@/components/MapPointer';
import ContentContainer from '@/components/ContentContainer';
import Link from '@/components/Link';
import { colors } from '@/constants/theme';
import { useSettingItem, useMenuItem } from '@/hooks';
import { MenuItemTypes } from '@/typings/model';
import A1 from '@/components/A1/A1';
import SocialMediaLink from '@/components/SocialMediaLink';

function Menu({ menuStateHandler }: { menuStateHandler: () => void }) {
  const router = useRouter();
  const topMenu = useMenuItem(MenuItemTypes.Top);

  const a1 = useSettingItem('CONTACTS_PHONE_A1');
  const mts = useSettingItem('CONTACTS_PHONE_MTS');
  const viber = useSettingItem('CONTACTS_VIBER');
  const whatsUp = useSettingItem('CONTACTS_WHATSAPP');
  const telegram = useSettingItem('CONTACTS_TELEGRAM');

  return (
    <Component>
      <ContentContainer>
        <Contancts>
          {a1 && <A1 title={a1} />}
          {mts && <Mts title={mts} />}
        </Contancts>
        <Messengers>
          {viber && <SocialMediaLink type="Viber" reference={viber} />}
          {whatsUp && <SocialMediaLink type="WhatsUp" reference={whatsUp} />}
          {telegram && <SocialMediaLink type="Telegram" reference={telegram} />}
        </Messengers>
        <Discount>
          При заказе через мессенджеры - <HighLight>2% скидка</HighLight>
        </Discount>
        <Map>
          <MapPointer />
        </Map>
        <List>
          {(topMenu ?? []).map(({ id, label, link }) => (
            <Item
              key={id}
              onClick={() => {
                router.push(link ?? '');
                menuStateHandler();
              }}
            >
              <li>{label}</li>
            </Item>
          ))}
        </List>
      </ContentContainer>
    </Component>
  );
}

const HighLight = styled.div`
  display: inline-block;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;

  color: ${colors.darkRed};
`;
const Map = styled.div`
  margin-top: 24px;
  min-width: 0;
  max-width: 320px;
`;
const Component = styled.div`
  overflow: auto;

  &:first-child {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
`;
const Contancts = styled.div`
  margin-top: 20px;

  span {
    margin-left: 10px;
  }

  &:last-child {
    margin-top: 10px;
  }
`;
const Messengers = styled.div`
  display: grid;
  grid-template-columns: auto;
  justify-content: start;
  grid-auto-flow: column;
  gap: 13px;

  margin-top: 18px;
`;
const Discount = styled.div`
  display: inline-block;
  margin-top: 13px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
`;
const List = styled.ul`
  ul {
    margin-top: 18px;
  }

  li {
    font-size: 13px;
    line-height: 36px;
  }
`;

const Item = styled.div`
  cursor: pointer;
`;

export default Menu;
