import React from 'react';
import styled from 'styled-components';

import { useModal } from '@tager/web-components';

import CallbackFormFooter from '@/components/Forms/CallbackForm';
import UseModalsOpen from '@/hooks/useModalsOpen';

function Callback() {
  const openModal = useModal();

  const { openModalState } = UseModalsOpen();

  return (
    <Component
      onClick={() => {
        setTimeout(() => {
          openModalState();
          openModal(
            CallbackFormFooter,
            {
              title: 'Заказать звонок',
            },
            { withAnimation: false, scrollLockDisabled: true }
          );
        }, 0);
      }}
    >
      <button>Перезвоните мне</button>
    </Component>
  );
}

const Component = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 34px;
  min-width: 140px;
  max-width: 290px;
  margin-left: 9px;

  border-radius: 6px;
  border: 1px solid #860935;

  button {
    width: 100%;
    height: 100%;
    color: #860935;
    font-weight: bold;
    font-size: 11px;
    line-height: 22px;
  }
`;

export default Callback;
