import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useRouter } from 'next/router';

import { useDebounce, useUpdateEffect } from '@tager/web-core';

import {
  getSearchCategoryListThunk,
  getSearchPostListThunk,
  getSearchProductListThunk,
  queryAdded,
  selectQuery,
} from '@/store/reducers/pages/search';
import SearchInput from '@/components/Input/SearchInput';
import { ReactComponent as Icon } from '@/assets/svg/Modals/close.svg';
import TabsBlock from '@/components/TabsBlock';
import SearchModalResultCatalog from '@/components/Search/components/SearchModal/components/SearchModalResultCatalog';
import SearchModalResultBlog from '@/components/Search/components/SearchModal/components/SearchModalResultBlog';
import SearchTitle from '@/components/Title/SearchTitle';
import UseModalsOpen from '@/hooks/useModalsOpen';
import { useBodyPositionFixed } from '@/hooks/useBodyPositionFixed';

const tabs = [
  { name: 'В каталоге', value: 'CATALOG' },
  { name: 'В статьях', value: 'BLOG' },
];

type Props = {
  closeModal: () => void;
};

function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function SearchModal({ closeModal }: Props) {
  const [isActiveTab, setActiveTabs] = useState('CATALOG');

  const dispatch = useDispatch();
  const searchQuery = useSelector(selectQuery);
  const debouncedSearchQuery = useDebounce(searchQuery, 300);
  const router = useRouter();
  const prevPath = usePrevious(router.asPath);
  const { closeModalState, openModalState } = UseModalsOpen();

  useBodyPositionFixed();

  useUpdateEffect(() => {
    dispatch(getSearchProductListThunk(debouncedSearchQuery, 'searchInput'));
    dispatch(getSearchPostListThunk(debouncedSearchQuery, 'searchInput'));
    dispatch(getSearchCategoryListThunk(debouncedSearchQuery, 'searchInput'));
  }, [debouncedSearchQuery, dispatch]);

  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (prevPath && prevPath !== router.asPath) {
      closeModal();
    }
  }, [router.asPath, prevPath, closeModal]);

  useEffect(() => {
    openModalState();
    return () => closeModalState();
  }, [closeModalState, openModalState]);

  const openPage = () => {
    if (!searchQuery.trim().length) {
      return;
    }
    router.push(`/search?query=${searchQuery}`);
  };

  return (
    <Component ref={ref}>
      <Header>
        <Search>
          <SearchInput
            ref={inputRef}
            variant="default"
            placeholder="Поиск"
            value={searchQuery}
            onChange={(e) => {
              dispatch(queryAdded(e.target.value));
            }}
            onKeyDown={(event) =>
              event.keyCode === 13 && !!searchQuery.trim().length
                ? (router.push(`/search?query=${searchQuery}`),
                  dispatch(queryAdded('')))
                : null
            }
            onClickButton={openPage}
          />
          <Close
            onClick={() => {
              closeModal();
              if (debouncedSearchQuery) {
                dispatch(queryAdded(''));
              }
            }}
          />
        </Search>
        <StyledTabsBlock
          variant="small"
          tabs={tabs}
          activeTab={isActiveTab}
          setActiveTab={setActiveTabs}
        />
      </Header>
      <Result>
        {!searchQuery ? (
          <SearchTitle title="Введите в поисковую строку ваш запрос" />
        ) : isActiveTab === 'CATALOG' ? (
          <SearchModalResultCatalog />
        ) : (
          <SearchModalResultBlog />
        )}
      </Result>
    </Component>
  );
}

export default SearchModal;

const Component = styled.div`
  z-index: 20;
  height: min(calc(var(--vh, 1) * 100), 100vh);
  min-width: min(100vw, 1260px);
  overflow: auto !important;

  background: #fff;
  padding-bottom: 10px;
`;

const Search = styled.div`
  padding: 15px;
  display: flex;
  align-items: center;

  & > div:first-child {
    flex: 1;
  }
`;

const Close = styled(Icon)`
  cursor: pointer;
  margin-left: 10px;
`;

const Result = styled.div`
  margin-top: 125px;

  padding: 0 15px;
`;

const StyledTabsBlock = styled(TabsBlock)`
  padding: 0 20px;
`;

const Header = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: #fff;
`;
