import { useEffect } from 'react';

import useModalsOpen from '@/hooks/useModalsOpen';

export function useBodyPositionFixed() {
  const { closeModalState, openModalState } = useModalsOpen();

  useEffect(() => {
    const body = document.body;
    const offsetY = window.pageYOffset;
    body.style.position = 'fixed';
    body.style.overflow = 'hidden';
    body.style.top = 'offsetYpx';
    openModalState();
    return () => {
      body.style.position = '';
      body.style.overflow = '';
      window.scrollTo(0, offsetY);
      closeModalState();
    };
  }, [closeModalState, openModalState]);
}
