import React from 'react';
import styled from 'styled-components';

import { colors } from '@/constants/theme';
import ContentContainer from '@/components/ContentContainer';
import Link from '@/components/Link';

function TagerInfo() {
  return (
    <Component>
      <ContentContainer>
        <Link to="https://ozitag.com/tager">
          <span>
            Работает на{' '}
            <Highlight>
              <b>TAGER</b>
            </Highlight>
          </span>
        </Link>
      </ContentContainer>
    </Component>
  );
}

export default TagerInfo;

const Component = styled.div`
  padding: 10px 0 5px;

  * {
    color: #000;
    font-size: 13px;
  }

  span:hover b {
    color: ${colors.darkRed};
  }

  span {
    cursor: pointer;
  }
`;

const Highlight = styled.span`
  font-weight: 600;
  text-transform: uppercase;
`;
