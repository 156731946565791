import React from 'react';

import Spinner, { Overlay } from '@/components/Spinner';

import * as S from './Button.style';
import { ButtonVariant } from './Button.types';

type CommonProps = {
  variant?: ButtonVariant;
  disabled?: boolean;
  loading?: boolean;
  as?: React.ElementType;
  width?: string;
  onClick?: () => void;
};

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & CommonProps;

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      type = 'button',
      loading = false,
      children,
      onClick,
      width,
      ...rest
    }: ButtonProps,
    ref: React.Ref<HTMLButtonElement>
  ) => {
    return (
      <S.StyledButton
        onClick={onClick}
        width={width}
        ref={ref}
        type={type}
        loading={loading}
        {...rest}
        children={
          loading ? (
            <>
              {children}
              <Overlay>
                <Spinner color="primary" absolute={true} />
              </Overlay>
            </>
          ) : (
            children
          )
        }
      />
    );
  }
);

type AnchorProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & CommonProps;

export const ButtonLink = React.forwardRef<HTMLAnchorElement, AnchorProps>(
  (props, ref) => {
    return <S.StyledButton as="a" ref={ref} {...props} />;
  }
);

export default Button;
