import React, { useState } from 'react';
import styled from 'styled-components';

import { ModalProps } from '@tager/web-components';

import UseModalsOpen from '@/hooks/useModalsOpen';
import SuccessForm from '@/components/Forms/SuccessForm';
import { ReactComponent as CloseIcon } from '@/assets/svg/Modals/close.svg';
import { useBodyPositionFixed } from '@/hooks/useBodyPositionFixed';

import FeedbackRequest from './FeedbackRequest';

type CallbackFormProps = ModalProps<{ title: string }>;

function FeedbackFormFooter({ closeModal, innerProps }: CallbackFormProps) {
  const [isFormClosed, setFormClosed] = useState(false);
  const { closeModalState } = UseModalsOpen();

  useBodyPositionFixed();

  return (
    <Container>
      <Header>
        <HeaderBody>
          <Title>{innerProps.title}</Title>
          <Close
            onClick={() => {
              closeModal();
              setTimeout(() => closeModalState(), 350);
            }}
          >
            <CloseIcon />
          </Close>
        </HeaderBody>
      </Header>
      {isFormClosed ? (
        <SuccessForm
          title="Сообщение отправлено"
          textNote="Мы благодарим вас за обращение"
          text="В ближайшее время руководство прочитает ваше сообщение, и если будет необходимо, мы свяжемся с вами по указанному телефону"
        />
      ) : (
        <FeedbackRequest setFormClosed={() => setFormClosed(true)} />
      )}
    </Container>
  );
}

export default FeedbackFormFooter;

const Container = styled.div`
  height: calc(var(--vh, 1) * 100);
  min-width: 100vw;
  padding-bottom: 15px;
  background: #fff;
  flex-shrink: 1;
  -webkit-overflow-scrolling: touch !important;
  overflow: auto !important;
`;

const Header = styled.div`
  box-shadow: 0 1px 6px 0 #d0d0d0;
`;

const HeaderBody = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  min-height: 45px;
`;
//
const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
`;
const Close = styled.div`
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: calc(50% - 8px);
`;
