import React from 'react';
import styled from 'styled-components';

import { isStringGuard, Nullable } from '@tager/web-core';
import {
  convertThumbnailToPictureProps,
  ThumbnailType,
} from '@tager/web-modules';
import { PictureProps } from '@tager/web-components';

import { colors } from '@/constants/theme';
import Picture, { PictureMediaQueryType } from '@/components/Picture';
import { PostShort } from '@/typings/model';
import Placeholder from '@/assets/images/placeholder.png';
import Link from '@/components/Link';

type Props = Omit<PostShort, 'coverImage'> & {
  coverImage?: Nullable<ThumbnailType> | string;
  categoryHidden?: boolean;
};

function PostCardBig({ coverImage, categoryHidden, ...post }: Props) {
  const pictureProps: PictureProps<PictureMediaQueryType> = isStringGuard(
    coverImage
  )
    ? { src: coverImage }
    : convertThumbnailToPictureProps(coverImage) ?? { src: '' };

  const category = categoryHidden ? null : post.category?.name;
  return (
    <Link to={`/blog/${post?.urlAlias}`}>
      <Content>
        {coverImage ? (
          <PostCover {...pictureProps} loading="lazy" useSpinner />
        ) : (
          <PostCover src={Placeholder} />
        )}
        <Title>{post.name}</Title>
        <Text>{post.excerpt}</Text>
        <Category>{category}</Category>
      </Content>
    </Link>
  );
}

const PostCover = styled(Picture)`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0.3), rgb(0, 0, 0, 1) 70%);
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    opacity: 60%;
  }
`;

const Title = styled.span`
  font-size: 22px;
  line-height: 28px;
  color: ${colors.white};
  font-weight: 500;
  display: block;
  margin-bottom: 15px;
  z-index: 100;
`;

const Text = styled.p`
  font-size: 13px;
  line-height: 22px;
  display: block;
  color: ${colors.white};
  z-index: 100;
`;

const Content = styled.div`
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  padding: 30px 40px;
  transition: 0.3s;

  &:hover {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    opacity: 0.85;
  }

  &:hover ${Title}, &:hover ${Text} {
    color: rgba(255, 255, 255, 0.85);
  }
`;

const Category = styled.span`
  position: absolute;
  right: 0;
  bottom: 0;
  background: ${colors.primary};
  color: ${colors.white};
  line-height: 30px;
  padding: 0 20px;
  font-size: 13px;
  &:hover {
    background: ${colors.primary100};
  }
`;

export default PostCardBig;
