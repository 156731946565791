import React from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import styled from 'styled-components';

import { CallFormPayload, sendCallForm } from '@/services/requests';
import { TextInputFormik } from '@/components/TextInput';
import Button from '@/components/Button';
import {
  areAllErrorsVisible,
  validatePhone,
} from '@/components/TextInput/formikHelpers/form.helpers';
import UseModalsOpen from '@/hooks/useModalsOpen';

function validate({ phone, name }: CallFormPayload) {
  const errors: Partial<CallFormPayload> = {};
  if (!validatePhone(phone)) {
    errors.phone = 'Неверный номер телефона';
  }
  if (!phone) {
    errors.phone = 'Поле обязательно для заполнения';
  }
  if (!name) {
    errors.name = 'Поле обязательно для заполнения';
  }
  return errors;
}

const hasFrontendValidationErrors = (values: CallFormPayload) =>
  !!Object.keys(validate(values)).length;

function CallbackFormRequest({ setFormClosed }: { setFormClosed: () => void }) {
  const { closeModalState } = UseModalsOpen();

  function handleSubmit(
    values: CallFormPayload,
    formikHelpers: FormikHelpers<CallFormPayload>
  ) {
    return sendCallForm(values)
      .then(() => {
        setFormClosed();
        closeModalState();
        formikHelpers.setSubmitting(true);
      })
      .catch(() => {
        formikHelpers.setErrors({
          phone: 'Произошла ошибка при отправке данных',
        });
        formikHelpers.setStatus({
          hasBackendError: true,
        });
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
      });
  }
  return (
    <Body>
      <Text>Оставьте ваши контакты и мы перезвоним в течение 15 минут</Text>
      <Formik<CallFormPayload>
        initialValues={{ name: '', phone: '' }}
        onSubmit={handleSubmit}
        validate={validate}
      >
        {({ isSubmitting, isValid, values, dirty, ...props }) => (
          <Form>
            <FormRow>
              <TextInputFormik
                name="name"
                icon="profile"
                placeholder="Как вас зовут?"
                autoComplete="off"
                invalid={false}
              />
            </FormRow>
            <FormRow>
              <TextInputFormik
                name="phone"
                icon="phone"
                placeholder="Ваш номер телефона"
                autoComplete="off"
                invalid={false}
              />
            </FormRow>
            <Button
              type="submit"
              variant="contained-primary-w100-f13"
              disabled={
                !(isValid || !hasFrontendValidationErrors(values)) &&
                areAllErrorsVisible(props.errors, props.touched)
              }
              children="Отправить"
              loading={isSubmitting}
            />
          </Form>
        )}
      </Formik>
    </Body>
  );
}

export default CallbackFormRequest;

const Text = styled.div`
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 20px;
`;

const FormRow = styled.div`
  margin-bottom: 30px;
`;

const Body = styled.div`
  margin-top: 20px;
  padding: 0 15px;
`;
