import React from 'react';
import styled from 'styled-components';

import ContentContainer from '@/components/ContentContainer';
import { colors } from '@/constants/theme';
import { useSettingItem } from '@/hooks';

function About() {
  const about = useSettingItem('FOOTER_COPYRIGHT');

  return (
    <DesktopLinkSection>
      <ContentContainer>
        <AboutDescription>{about}</AboutDescription>
      </ContentContainer>
    </DesktopLinkSection>
  );
}

const DesktopLinkSection = styled.div`
  padding: 10px 0 5px;
  border-bottom: 1px solid ${colors.grayDivider};
`;

const AboutDescription = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 18px;
  color: ${colors.black};
`;

export default About;
