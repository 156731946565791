import React from 'react';
import styled from 'styled-components';

import { Nullable } from '@tager/web-core';

import Picture from '@/components/Picture';
import a1 from '@/assets/images/Layout/Footer/A1.png';
import Link from '@/components/Link';

type Number = {
  title?: Nullable<string>;
};

function A1({ title }: Number) {
  return (
    <Component>
      <Link to={`tel:${title}`}>
        <Picture src={a1} />
        <span>{title}</span>
      </Link>
    </Component>
  );
}

export default A1;

const Component = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  min-height: 34px;
  min-width: 140px;
  max-width: 320px;

  img {
    height: 18px;
  }

  span {
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 20px;
    margin-left: 4px;
    white-space: nowrap;
  }

  & > a {
    display: flex;
  }
`;
