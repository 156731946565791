import React, { useContext } from 'react';
import styled from 'styled-components';

import Picture from '@/components/Picture';
import MobileLogo from '@/assets/svg/Header/Logo/BelMebel.mobile.logo.svg';
import { LogoContext } from '@/providers/LogoTriggerProvider/LogoTriggerProvider';

function Logo() {
  const { triggerLogo } = useContext(LogoContext);

  return (
    <Component onClick={() => triggerLogo?.(true)}>
      <Picture src={MobileLogo} />
    </Component>
  );
}

const Component = styled.div`
  img {
    cursor: pointer;
    height: 20.97px;
  }
`;

export default Logo;
