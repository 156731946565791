import React from 'react';
import styled from 'styled-components';

import { FETCH_STATUSES, generateNumberArray } from '@tager/web-core';

import { useTypedSelector } from '@/store/store';
import {
  selectFindCategoryListResource,
  selectFindProductListResource,
  selectQuery,
} from '@/store/reducers/pages/search';
import SearchModalResultTitle from '@/components/Title/SearchTitle';
import { getProductsOrPostsOrCategoriesWord } from '@/utils/common';
import Link from '@/components/Link';
import PlaceholderCard from '@/components/PlaceholderCard';
import ProductCardVertical from '@/components/ProductCard/ProductCardProductCardVertical';
import Button from '@/components/Button';
import { colors } from '@/constants/theme';

function SearchModalResultCatalog() {
  const categories = useTypedSelector((state) =>
    selectFindCategoryListResource(state, 'searchInput')
  );
  const products = useTypedSelector((state) =>
    selectFindProductListResource(state, 'searchInput')
  );

  const searchQuery = useTypedSelector(selectQuery);

  if (
    !categories.data.length &&
    categories.status === FETCH_STATUSES.SUCCESS &&
    !products.data.length &&
    products.status === FETCH_STATUSES.SUCCESS
  ) {
    return (
      <SearchModalResultTitle title="По вашему запросу ничего не найдено" />
    );
  }

  return (
    <Container>
      <ResultWrapper>
        {categories.data.map((category) => (
          <CategoryWrapper key={category.id}>
            <CategoryLink to={`/catalog${category.urlPath}`}>
              {category.name}
              {category.productsCount ? (
                <Count>{`${
                  category.productsCount
                } ${getProductsOrPostsOrCategoriesWord(
                  category.productsCount,
                  'Products'
                )}`}</Count>
              ) : null}
            </CategoryLink>
          </CategoryWrapper>
        ))}
      </ResultWrapper>
      <ResultWrapper>
        {products.status === FETCH_STATUSES.LOADING ? (
          generateNumberArray(1).map((num) => (
            <ProductWrapper key={num}>
              <PlaceholderCard height={170} />
            </ProductWrapper>
          ))
        ) : (
          <>
            {products.data.slice(0, 5).map((product) => (
              <ProductWrapper key={product.id}>
                <LinkContainer to={`/products/${product.urlAlias}`}>
                  <ProductCardVertical {...product} />
                </LinkContainer>
              </ProductWrapper>
            ))}
            {products.data.length > 5 ? (
              <Link to={`/search?query=${searchQuery}`}>
                <Button variant="outlined-primary-w100">
                  Показать больше результатов
                </Button>
              </Link>
            ) : null}
          </>
        )}
      </ResultWrapper>
    </Container>
  );
}
export default SearchModalResultCatalog;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ResultWrapper = styled.ul``;

const CategoryWrapper = styled.li`
  display: flex;
  margin-bottom: 15px;
`;

const CategoryLink = styled(Link)`
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  &:hover {
    color: ${colors.primary};
  }
`;

const Count = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: #9b9b9b;
  margin-left: 10px;
`;

const ProductWrapper = styled.li`
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const LinkContainer = styled(Link)`
  width: 100%;
`;
