import React from 'react';
import styled from 'styled-components';

import Link from '@/components/Link';
import { ReactComponent as ViberIcon } from '@/assets/svg/Menu/viber.svg';
import { ReactComponent as WhatsUpIcon } from '@/assets/svg/Menu/whatsapp.svg';
import { ReactComponent as TelegramIcon } from '@/assets/svg/Menu/telegram.svg';

type SocialMedia = 'Viber' | 'Telegram' | 'WhatsUp' | 'Skype';
type Props = {
  type: SocialMedia;
  reference: string;
};

function SocialMediaLink({ type, reference }: Props) {
  switch (type) {
    case 'Viber':
      return (
        <Component>
          <Link to={`viber://add?number=${reference}`}>
            <ViberIcon />
          </Link>
        </Component>
      );
    case 'Telegram':
      return (
        <Component>
          <Link to={`https://t.me/${reference}`}>
            <TelegramIcon />
          </Link>
        </Component>
      );
    case 'WhatsUp':
      return (
        <Component>
          <Link to={`https://wa.me/${reference}`}>
            <WhatsUpIcon />
          </Link>
        </Component>
      );

    default:
      return null;
  }
}

const Component = styled.div``;

export default SocialMediaLink;
