import React from 'react';
import styled from 'styled-components';

import ContentContainer from '@/components/ContentContainer';
import { ReactComponent as MonitorIcon } from '@/assets/svg/Footer/Monitor.icon/monitor.svg';
import { colors } from '@/constants/theme';
import Link from '@/components/Link';

function Desktop() {
  return (
    <DesktopLinkSection>
      <ContentContainer>
        <Component>
          <Icon>
            <MonitorIcon />
          </Icon>
          <Link to={'https://belmebel.by/'}>
            <div>Полная версия сайта</div>
          </Link>
        </Component>
      </ContentContainer>
    </DesktopLinkSection>
  );
}
export default Desktop;

const Component = styled.div`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: ${colors.black100};
`;

const DesktopLinkSection = styled.div`
  padding: 10px 0;
  border-bottom: 1px solid ${colors.grayDivider};
`;

const Icon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 10px;

  div {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;

    color: ${colors.black};
  }
`;
