import React from 'react';
import styled from 'styled-components';

import { isStringGuard, Nullable } from '@tager/web-core';
import {
  convertThumbnailToPictureProps,
  ThumbnailType,
} from '@tager/web-modules';
import { PictureProps } from '@tager/web-components';

import { colors } from '@/constants/theme';
import Picture, { PictureMediaQueryType } from '@/components/Picture';
import { PostShort } from '@/typings/model';
import Link from '@/components/Link';
import Placeholder from '@/assets/images/placeholder.png';

type Props = Omit<PostShort, 'coverImage'> & {
  coverImage?: Nullable<ThumbnailType> | string;
  categoryHidden?: boolean;
};

function PostCardSmall({ coverImage, categoryHidden, ...post }: Props) {
  const pictureProps: PictureProps<PictureMediaQueryType> = isStringGuard(
    coverImage
  )
    ? { src: coverImage }
    : convertThumbnailToPictureProps(coverImage) ?? { src: '' };

  const category = categoryHidden ? null : post.category?.name;
  return (
    <StyledLink to={`/blog/${post?.urlAlias}`}>
      <Content>
        {coverImage ? (
          <PostCover {...pictureProps} loading="lazy" useSpinner />
        ) : (
          <PostCover src={Placeholder} loading="lazy" />
        )}
        <Title>{post.name}</Title>
        <Text>{post.excerpt}</Text>
        <Category>{category}</Category>
      </Content>
    </StyledLink>
  );
}

const StyledLink = styled(Link)`
  width: 100%;
`;

const PostCover = styled(Picture)`
  display: inline-block;
  overflow: hidden;
  width: 100%;
  img {
    object-fit: cover;
    width: 100%;
    transition: all 0.45s ease-in-out 0s;
    height: 200px;
  }
`;

const Title = styled.span`
  padding: 20px 15px 10px;
  font-size: 21px;
  line-height: 28px;
  font-weight: 500;
  display: block;
  text-align: left;
`;

const Content = styled.div`
  display: block;
  background: ${colors.white};
  height: 100%;
  padding-bottom: 35px;
  position: relative;
  transition: 0.3s;
  color: ${colors.black};
  border: 1px solid ${colors.gray100};
  &:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.06);
  }
  &:hover ${PostCover} {
    img {
      transform: scale(1.1);
    }
  }
  &:hover ${Title} {
    color: ${colors.primary};
  }
`;

const Text = styled.p`
  padding: 0 20px;
  font-size: 13px;
  line-height: 22px;
  display: -webkit-box;
  height: 44px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const Category = styled.span`
  position: absolute;
  right: 0;
  bottom: 0;
  background: ${colors.primary};
  color: ${colors.white};
  line-height: 30px;
  padding: 0 20px;
  font-size: 13px;
  &:hover {
    background: ${colors.primary100};
  }
`;

export default PostCardSmall;
