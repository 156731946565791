import React from 'react';
import styled from 'styled-components';

import {
  convertThumbnailToPictureProps,
  ThumbnailType,
} from '@tager/web-modules';
import { isStringGuard, Nullable } from '@tager/web-core';
import { PlainPictureProps } from '@tager/web-components';
import { media } from '@tager/web-components/dist/config/media';

import { breakpoints, colors } from '@/constants/theme';
import { PictureMediaQueryType, PlainPicture } from '@/components/Picture';
import { PostShort } from '@/typings/model';
import Placeholder from '@/assets/images/placeholder.png';
import Link from '@/components/Link';

type Props = Omit<PostShort, 'coverImage'> & {
  coverImage?: Nullable<ThumbnailType> | string;
};

function PostCardSearch({ coverImage, ...post }: Props) {
  const pictureProps: PlainPictureProps<PictureMediaQueryType> = isStringGuard(
    coverImage
  )
    ? { src: coverImage }
    : convertThumbnailToPictureProps(coverImage) ?? { src: '' };

  return (
    <Link to={`/blog/${post?.urlAlias}`}>
      <Content>
        {coverImage ? (
          <ImageWrapper>
            <PostCover {...pictureProps} />
          </ImageWrapper>
        ) : (
          <ImageWrapper>
            <PostCover src={Placeholder} />
          </ImageWrapper>
        )}
        <div>
          <Title>{post.name}</Title>
          <Text>{post.excerpt}</Text>
        </div>
      </Content>
    </Link>
  );
}

const ImageWrapper = styled.div`
  height: 140px;
  margin-bottom: 15px;

  @media (min-width: ${breakpoints.tabletSmall}px) {
    height: 200px;
  }
`;

const PostCover = styled(PlainPicture)`
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Title = styled.span`
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const Text = styled.p`
  display: block;
  margin-top: 10px;
  font-size: 13px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.white};
  height: 100%;
  padding: 14px;
  position: relative;
  transition: 0.3s;
  color: ${colors.black};
  border: 1px solid ${colors.gray100};
  &:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.06);
  }
  &:hover ${Title} {
    color: ${colors.primary};
  }
`;

export default PostCardSearch;
